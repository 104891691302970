import { getTiles } from '../../pages/PhotoStyler/tilesSaver'
import {
  STORAGE_KEYS,
  TILES_SOURCE,
} from '../../components/TilesProvider/TilesProvider.consts'
import { loadPhotoBook } from '../PhotoBook/photoBookSaver'
import { isArtPhoto } from '@mixtiles/web-backend-shared'
import { type Tile } from 'components/TilesProvider/TilesProvider.types'

export function generateProductCountEventProperties() {
  const tiles = getTiles(STORAGE_KEYS[TILES_SOURCE.PHOTO_STYLER])
  const artTilesCount = tiles.filter((t) =>
    isArtPhoto(t as Partial<Tile>)
  ).length
  const tilesCount = tiles.length - artTilesCount
  const jumblesCount = getTiles(STORAGE_KEYS[TILES_SOURCE.JUMBLE]).length

  const photoBook = loadPhotoBook()
  const photosCount = photoBook?.pages?.reduce(
    (acc, p) => acc + p?.front?.photos?.length + p?.back?.photos?.length,
    0
  )
  return {
    'Tiles Count': tilesCount,
    'Jumbles Count': jumblesCount,
    'Art Tiles Count': artTilesCount,
    'Photo Book Photos Count': photosCount || 0,
  }
}
